import _merge from 'lodash/merge'
import { ACC_THEME } from '../themes'
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const ACC_DARK_THEME = {
  palette: {
    type: 'dark',
    text: {
      primary: '#FFF',
      tertiary: '#FFF',
    },
    background: {
      primary: '#000',
    },
  },
}

export default responsiveFontSizes(
  createTheme(_merge(ACC_THEME, ACC_DARK_THEME)),
  {
    disableAlign: true,
    factor: 4,
  }
)
//
